import React, { useState } from 'react';
import {
    Heading,
    VStack,
    IconButton,
    useDisclosure,
    HStack,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import PortfolioInfoList from './PortfolioInfoList';
import AddPortfolioModal from './AddPortfolioModal';
import AccountDrawer from './AccountDrawer';
import { Portfolio } from '../interfaces/Portfolio';
import FloatingActionButton from './FloatingActionButton';

const Home: React.FC = () => {
    const {
        isOpen: isAddPortfolioOpen,
        onOpen: onAddPortfolioOpen,
        onClose: onAddPortfolioClose,
    } = useDisclosure();

    const {
        isOpen: isDrawerOpen,
        onOpen: onDrawerOpen,
        onClose: onDrawerClose,
    } = useDisclosure();

    const [key, setKey] = useState<number>(0);

    const handleAddPortfolio = (portfolio: Portfolio) => {
        setKey((prevKey) => prevKey + 1);
        onAddPortfolioClose();
    };

    return (
        <VStack align="stretch" spacing={4}>

            <HStack justifyContent="space-between" alignItems="center">
                <Heading as="h3" size="lg">
                    Portfolios
                </Heading>
                <IconButton
                    colorScheme="blue"
                    aria-label="Menu"
                    icon={<HamburgerIcon boxSize={6} />}
                    variant="ghost"
                    size="lg"
                    onClick={onDrawerOpen}
                />
            </HStack>

            <PortfolioInfoList key={key} />

            <AccountDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} />

            <AddPortfolioModal
                isOpen={isAddPortfolioOpen}
                onClose={onAddPortfolioClose}
                onAddPortfolio={handleAddPortfolio}
            />


            <FloatingActionButton
                onClick={onAddPortfolioOpen}
            />
        </VStack>
    );
};

export default Home;