// src/components/AccountDrawer.tsx

import React from 'react';
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    HStack,
    Text,
    Button,
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { useAuth } from '../contexts/AuthContext';

interface AccountDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const AccountDrawer: React.FC<AccountDrawerProps> = ({ isOpen, onClose }) => {
    const { isAuthenticated, logout } = useAuth();

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <HStack>
                        <FiUser />
                        <Text ml={2}>Account</Text>
                    </HStack>
                </DrawerHeader>

                <DrawerBody>
                    {/* Add any additional account-related content here */}
                </DrawerBody>

                <DrawerFooter>
                    {isAuthenticated && (
                        <Button colorScheme="red" onClick={logout} width="full">
                            Logout
                        </Button>
                    )}
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default AccountDrawer;