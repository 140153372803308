import React from 'react';
import { FormControl, FormLabel, Input, NumberInput, NumberInputField, Flex } from '@chakra-ui/react';

export interface FormInputProps {
    id: string;
    label: string;
    placeholder?: string;
    defaultValue?: string | number;
    type: 'text' | 'number' | 'date';
    isRequired?: boolean;
    isDisabled?: boolean;
    onChange: (value: string) => void;
}

export const FormInput: React.FC<FormInputProps> = ({
    id,
    label,
    placeholder,
    defaultValue,
    type,
    isRequired = false,
    isDisabled = false,
    onChange,
}) => {
    return (
        <FormControl isRequired={isRequired}>
            <Flex alignItems="center">
                <FormLabel htmlFor={id} mb={0} flex={1} textAlign="right">{label}</FormLabel>
                {type === 'number' ? (
                    <NumberInput flex={2.26} defaultValue={defaultValue as number} onChange={(e) => onChange(e)}>
                        <NumberInputField id={id} placeholder={placeholder} />
                    </NumberInput>
                ) : (
                    <Input id={id} placeholder={placeholder} type={type} flex={2} defaultValue={defaultValue as string} isDisabled={isDisabled} onChange={(e) => onChange(e.target.value)} />
                )}
            </Flex>
        </FormControl>
    );
};
