import React from 'react';
import { HStack, Spacer, Text } from '@chakra-ui/react';

interface TransactionDetailRowProps {
    label: string;
    value: React.ReactNode;
}

const TransactionDetailRow: React.FC<TransactionDetailRowProps> = ({ label, value }) => (
    <HStack spacing={6}>
        <Text fontWeight={"medium"} color={"gray.600"} >{label}</Text>
        <Spacer />
        {typeof value === 'string' || typeof value === 'number' ? (
            <Text color={"black"} isTruncated>{value}</Text>
        ) : (
            value
        )}
    </HStack>
);

export default TransactionDetailRow;