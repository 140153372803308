import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Spinner, Center } from '@chakra-ui/react';

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        // Show a loading spinner while checking authentication
        return (
            <Center h="60vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (!isAuthenticated) {
        // Redirect unauthenticated users to the home page
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;