import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Flex,
    Heading,
    VStack,
    IconButton,
    Card,
    CardBody,
    Stat,
    Spacer,
    StatHelpText,
    StatNumber,
    Box,
    Grid,
    GridItem,
    Center,
    Spinner
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import FormattedText from '../FormattedText';
import { InvestmentDetailModel } from '../../interfaces/InvestmentDetailModel';
import TransactionPerInvestmentTable from './TransactionPerInvestmentTable';
import { useAuth } from '../../contexts/AuthContext';
import { atom, useAtom } from 'jotai';

const modelAtom = atom<InvestmentDetailModel | null>(null);

const InvestmentDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { service } = useAuth();
    const [model, setModel] = useAtom(modelAtom);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchInvestmentDetail = async () => {
            try {
                if (service && id) {
                    const data = await service.fetchInvestmentDetail(id);
                    setModel(data);
                } else {
                    console.error('Invalid portfolio ID.');
                }
            } catch (err) {
                console.error('Failed to fetch portfolio:', err);
            }
        };

        fetchInvestmentDetail();
    }, [service, id, setModel]);

    return (
        <>
            {model ? (
                <>
                    <Flex align="baseline" mb={6}>
                        <IconButton
                            icon={<ArrowBackIcon />}
                            onClick={() => navigate(-1)}
                            colorScheme="blue"
                            aria-label="Go back"
                            isRound
                            mr={4}
                        />

                        <Heading as="h2" size="lg">
                            {model.name}
                        </Heading>
                    </Flex>

                    <VStack align="stretch" spacing={4} mb={4}>
                        <Flex
                            direction={['column', 'row']}
                            mb={4}
                            mt={4}
                            gap={4}
                        >
                            <Heading size="md">Total holdings</Heading>
                            <Spacer />
                        </Flex>

                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                            <GridItem>
                                <Card>
                                    <CardBody>
                                        <Stat>
                                            <StatNumber>
                                                <FormattedText
                                                    value={model.value}
                                                    type='price'
                                                />
                                            </StatNumber>
                                            <StatHelpText>Current value</StatHelpText>
                                        </Stat>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card>
                                    <CardBody>
                                        <Stat>
                                            <StatNumber>
                                                <FormattedText
                                                    value={model.profit}
                                                    type="priceChange"
                                                />
                                            </StatNumber>
                                            <StatHelpText>
                                                <FormattedText
                                                    value={model.totalReturn}
                                                    type='percentChange'
                                                />
                                            </StatHelpText>
                                        </Stat>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card>
                                    <CardBody>
                                        <Stat>
                                            <StatNumber>
                                                {model.numberOfShares}
                                            </StatNumber>
                                            <StatHelpText>
                                                Shares held
                                            </StatHelpText>
                                        </Stat>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card>
                                    <CardBody>
                                        <Stat>
                                            <StatNumber>
                                                <FormattedText
                                                    value={model.avgBuyPrice}
                                                    type="price"
                                                />
                                            </StatNumber>
                                            <StatHelpText>
                                                Avg. buy price
                                            </StatHelpText>
                                        </Stat>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </Grid>

                        <Flex
                            direction={['column', 'row']}
                            mb={4}
                            mt={4}
                            gap={4}
                        >
                            <Heading size="md">Transactions</Heading>
                            <Spacer />
                        </Flex>

                        <Box overflowX="auto">
                            <TransactionPerInvestmentTable
                                transactions={model.transactions}
                            />
                        </Box>
                    </VStack>
                </>
            ) : (
                <Center h="60vh">
                    <Spinner size="xl" />
                </Center>
            )}
        </>
    );
};

export default InvestmentDetail;