// src/components/TransactionPerInvestmentTable.tsx

import React from 'react';
import {
    Box,
    VStack,
    Text,
    HStack,
    Card,
    Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import FormattedText from '../FormattedText';
import { Transaction } from '../../interfaces/Transaction';

interface TransactionPerInvestmentTableProps {
    transactions: Transaction[];
}

const TransactionPerInvestmentTable: React.FC<TransactionPerInvestmentTableProps> = ({
    transactions,
}) => {
    const groupByDate = (transactions: Transaction[]) => {
        return transactions.reduce<Record<string, Transaction[]>>((acc, transaction) => {
            const date = new Date(transaction.purchaseDate).toDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(transaction);
            return acc;
        }, {});
    };

    const groupedTransactions = groupByDate(transactions);
    const sortedDates = Object.keys(groupedTransactions).sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );

    const navigate = useNavigate();
    const handleTransactionClick = (id: string) => {
        navigate(`/transaction/${id}`);
    };

    return (
        <VStack align="stretch" spacing={4}>
            {sortedDates.map((date) => (
                <Box key={date}>
                    {/* Date Header */}
                    <Text fontSize="lg" mb={2}>
                        {date}
                    </Text>

                    {/* Transactions for the Date */}
                    <VStack align="stretch" spacing={4}>
                        {groupedTransactions[date].map((transaction) => (
                            <Card
                                key={transaction.id}
                                p={4}
                                onClick={() => handleTransactionClick(transaction.id)}
                                cursor="pointer"
                                _hover={{ boxShadow: 'lg' }}
                                borderWidth="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                boxShadow="sm"
                                transition="box-shadow 0.2s"
                            >
                                <HStack>
                                    <VStack align="flex-start" spacing={1}>
                                        <HStack>
                                            <Text fontWeight="medium">{transaction.portfolioName}</Text>
                                        </HStack>
                                        <HStack spacing="40px">
                                            <Text fontSize="sm">
                                                {transaction.numberOfShares} shares · {transaction.currency.symbol}
                                                {transaction.pricePerShareAtPurchase.toFixed(2)}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                    <Spacer />
                                    <FormattedText
                                        value={-1 * transaction.numberOfShares * transaction.pricePerShareAtPurchase}
                                        type='price'
                                    />
                                </HStack>
                            </Card>
                        ))}
                    </VStack>
                </Box>
            ))}
        </VStack>
    );
};

export default TransactionPerInvestmentTable;