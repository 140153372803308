import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

interface FloatingActionButtonProps extends ButtonProps {
    onClick: () => void;
    icon?: React.ReactElement;
}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({
    onClick,
    colorScheme = 'blue',            // Default color scheme
    bottom = '24px',
    right = '24px',
}) => {
    return (
        <Button
            onClick={onClick}
            colorScheme={colorScheme}
            position="fixed"
            bottom={bottom}
            right={right}
            borderRadius="full"
            shadow="md"
            width="56px"
            height="56px"
            padding={0}
            minWidth="unset"
        >
            <AddIcon boxSize={6} />
        </Button>
    );
};

export default FloatingActionButton;