import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Spacer,
  VStack,
  useDisclosure,
  Spinner,
  Center,
  IconButton,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import { atom, useAtom } from 'jotai';

import InvestmentsList from './InvestmentsList';
import { useAuth } from '../../contexts/AuthContext';
import { PortfolioPerformanceTimeSeriesDTO } from '../../interfaces/PortfolioPerformanceTimeSeriesDTO';
import AddTransactionModal from './AddTransactionModal';
import FloatingActionButton from '../FloatingActionButton';
import PerformanceCards from './PerformanceCards';
import PerformanceChart from './PerformanceChart';
import { Portfolio } from '../../interfaces/Portfolio';

const portfolioAtom = atom<Portfolio | null>(null);
const historicalDataAtom = atom<any[] | null>(null);

const PortfolioDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { service } = useAuth();
  const [portfolio, setPortfolio] = useAtom(portfolioAtom);
  const [historicalData, setHistoricalData] = useAtom(historicalDataAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isWideEnough] = useMediaQuery('(min-width: 1100px)');
  const navigate = useNavigate();

  const resetAtoms = () => {
    setPortfolio(null);
    setHistoricalData(null);
  };


  const convertHistoricalPerformance = useCallback(
    (raw: PortfolioPerformanceTimeSeriesDTO) => {
      if (!raw.values || raw.values.length === 0) {
        setHistoricalData([]);
        return;
      }

      const formattedData = raw.values.map((value) => ({
        date: new Date(value.date).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        value: value.moneyOut,
      }));

      const sortedData = [...formattedData].sort((a, b) => a.value - b.value);
      const lowest = sortedData[0];
      const highest = sortedData[sortedData.length - 1];

      const dataWithMarkers = formattedData.map((dataPoint) => ({
        ...dataPoint,
        isHighest: dataPoint.value === highest.value,
        isLowest: dataPoint.value === lowest.value,
      }));

      setHistoricalData(dataWithMarkers);
    },
    [setHistoricalData] // Dependencies of this function
  );


  const handleBackNavigation = () => {
    resetAtoms();
    navigate(-1);
  };

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        if (service && id) {
          const data = await service.fetchPortfolio(id);
          setPortfolio(data);
        } else {
          console.error('Invalid portfolio ID.');
        }
      } catch (err) {
        console.error('Failed to fetch portfolio:', err);
      }
    };

    const fetchPortfolioHistoricalPerformance = async () => {
      try {
        if (service && id) {
          const data = await service.fetchPortfolioHistoricalPerformance(id);
          convertHistoricalPerformance(data);
        }
      } catch (error) {
        console.error('Failed to fetch portfolio historical data:', error);
      }
    };

    fetchPortfolio();
    fetchPortfolioHistoricalPerformance();
  }, [service, id, setPortfolio, convertHistoricalPerformance]);


  const refetchTransactions = async () => {
    try {
      if (service && id) {
        const data = await service.fetchPortfolio(id);
        setPortfolio(data);
        onClose();
      } else {
        console.error('Invalid portfolio ID.');
      }
    } catch (err) {
      console.error('Failed to fetch portfolio details after adding transaction:', err);
    }
  };

  return (
    <>
      {portfolio ? (
        <>

          <Flex align="baseline" mb={6}>
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={handleBackNavigation}
              colorScheme="blue"
              aria-label="Go back"
              isRound
              mr={4}
            />

            <Heading as="h2" size="lg">
              {portfolio.name}
            </Heading>
          </Flex>

          {/* Performance Section */}
          <VStack align="stretch" spacing={4} mb={4}>

            <PerformanceCards portfolio={portfolio} />

            <PerformanceChart
              historicalData={historicalData}
              currencyCode={portfolio.currency.code}
            />
          </VStack>

          <Flex
            direction={['column', 'row']}
            mb={4}
            mt={4}
            gap={4}
          >
            <Heading size="md">Investments</Heading>
            <Spacer />
          </Flex>

          {/* Investments Table */}
          <Box overflowX="auto">
            {/* {isWideEnough ? (
              <InvestmentsTable investments={portfolio.investments} />
            ) : ( */}
            <InvestmentsList investments={portfolio.investments} />
            {/* )} */}
          </Box>

          <FloatingActionButton onClick={onOpen} />

          <AddTransactionModal
            portfolioID={portfolio.id}
            isOpen={isOpen}
            onClose={onClose}
            onAddTransaction={refetchTransactions}
          />
        </>
      ) : (
        <Center h="60vh">
          <Spinner size="xl" />
        </Center>
      )}
    </>
  );
};

export default PortfolioDetail;