import React from 'react';
import { Card, CardBody, Text } from '@chakra-ui/react';
import {
    LineChart,
    Line,
    XAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { CurrencyFormatter } from '../CurrencyFormatter';

interface PerformanceChartProps {
    historicalData: any[] | null;
    currencyCode: string;
}

const PerformanceChart: React.FC<PerformanceChartProps> = ({
    historicalData,
    currencyCode,
}) => {
    return (
        <Card>
            <CardBody>
                {historicalData && historicalData.length > 0 ? (
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={historicalData}
                            margin={{ top: 40, right: 50, left: 50, bottom: 0 }}
                        >
                            <XAxis
                                dataKey="date"
                                tickLine={false}
                                axisLine={false}
                                minTickGap={50}
                            />
                            <Tooltip
                                formatter={(value: number) =>
                                    CurrencyFormatter.formatCurrency(value, currencyCode)
                                }
                                labelFormatter={(label: string) => `Date: ${label}`}
                            />
                            <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#319795"
                                strokeWidth={3}
                                activeDot={{ r: 8 }}
                                dot={(props) => {
                                    const { cx, cy, index } = props;
                                    const dataPoint = historicalData[index];
                                    if (dataPoint.isHighest || dataPoint.isLowest) {
                                        return (
                                            <g>
                                                <circle cx={cx} cy={cy} r={6} fill="teal" />
                                                <text
                                                    x={cx}
                                                    y={dataPoint.isHighest ? cy - 10 : cy + 20}
                                                    textAnchor="middle"
                                                    fill="teal"
                                                >
                                                    {CurrencyFormatter.formatCurrency(
                                                        dataPoint.value,
                                                        currencyCode
                                                    )}
                                                </text>
                                            </g>
                                        );
                                    }
                                    return <g />;
                                }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <Text>No historical data available.</Text>
                )}
            </CardBody>
        </Card>
    );
};

export default PerformanceChart;