import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
    useCallback,
} from 'react';
import { Spinner, Center, useToast } from '@chakra-ui/react';
import LoginPage from '../components/LoginPage';
import { createGrodtService, IGrodtService } from '../api/GrodtService';

interface AuthContextProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    service: IGrodtService | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [service, setService] = useState<IGrodtService | null>(null);
    const [loginPromise, setLoginPromise] = useState<{
        resolve: () => void;
        reject: (reason?: any) => void;
    } | null>(null);

    const toast = useToast();

    // Memoized handle401 function
    const handle401 = useCallback(async (): Promise<void> => {
        setIsAuthenticated(false);
        return new Promise<void>((resolve, reject) => {
            setLoginPromise({ resolve, reject });
        });
    }, []);

    // Initialize service on mount
    useEffect(() => {
        const initializeAuth = async () => {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                console.log('Stored token found, initializing service and authenticating');
                const initializedService = createGrodtService(handle401);
                initializedService.setAuthToken(storedToken);
                setService(initializedService);
                setIsAuthenticated(true);
            } else {
                console.log('No stored token, showing LoginPage');
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        };
        initializeAuth();
    }, [handle401]);

    // Login function
    const login = useCallback(
        async (username: string, password: string): Promise<void> => {
            try {
                console.log('Attempting login with username:', username);
                const newService = createGrodtService(handle401);
                await newService.login(username, password);
                const token = newService.getAuthToken();
                if (token) {
                    localStorage.setItem('authToken', token);
                    newService.setAuthToken(token);
                    setService(newService);
                    setIsAuthenticated(true);
                    console.log('Login successful, token set:', token);
                    toast({
                        title: 'Logged in successfully.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    if (loginPromise) {
                        loginPromise.resolve();
                        setLoginPromise(null);
                    }
                } else {
                    throw new Error('Token not found after login.');
                }
            } catch (error) {
                console.error('Login failed:', error);
                toast({
                    title: 'Login failed.',
                    description: 'Invalid username or password.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                if (loginPromise) {
                    loginPromise.reject(error);
                    setLoginPromise(null);
                }
                throw error;
            }
        },
        [handle401, loginPromise, toast]
    );

    // Logout function
    const logout = useCallback(() => {
        console.log('Logging out...');
        localStorage.removeItem('authToken');
        setService(null);
        setIsAuthenticated(false);
        toast({
            title: 'Logged out.',
            status: 'info',
            duration: 2000,
            isClosable: true,
        });
    }, [toast]);

    if (isLoading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (!isAuthenticated) {
        return (
            <LoginPage
                onLogin={async (username, password) => {
                    await login(username, password);
                }}
            />
        );
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, service, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
};