import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { PortfolioInfo } from '../interfaces/PortfolioInfo';
import {
  Card,
  HStack,
  Text,
  SimpleGrid,
  Spinner,
  Center,
  Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import FormattedText from './FormattedText';
import { atom, useAtom } from 'jotai';

const portfoliosAtom = atom<PortfolioInfo[]>([]);
const loadingAtom = atom<boolean>(true);
const errorAtom = atom<string | null>(null);

const PortfolioInfoList: React.FC = () => {
  const [portfolios, setPortfolios] = useAtom(portfoliosAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [error, setError] = useAtom(errorAtom);
  const navigate = useNavigate();
  const { service } = useAuth();

  useEffect(() => {
    if (!portfolios.length && service) {
      setLoading(true);
      service
        .fetchPortfolios()
        .then((data) => {
          setPortfolios(data);
          setError(null);
        })
        .catch((err) => {
          console.error('Error fetching portfolios:', err);
          setError('Failed to fetch portfolio details.');
        })
        .finally(() => setLoading(false));
    }
  }, [service, portfolios, setPortfolios, setLoading, setError]);


  const handlePortfolioClick = (id: string) => {
    navigate(`/portfolio/${id}`);
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Text color="red.500">{error}</Text>
      </Center>
    );
  }

  return (
    <SimpleGrid spacing={4} minChildWidth="350px">
      {portfolios.map((portfolio) => (
        <Card
          key={portfolio.id}
          p={5}
          onClick={() => handlePortfolioClick(portfolio.id)}
          cursor="pointer"
          _hover={{ boxShadow: 'lg' }}
        >
          <HStack>
            <Text fontSize="xl" mb={2}>
              {portfolio.name}
            </Text>
            <Spacer />
            <FormattedText
              value={portfolio.performance.moneyOut}
              type='price'
            />
          </HStack>
          <HStack spacing={8}>
            <FormattedText
              value={portfolio.performance.totalReturn}
              type='percentChange'
            />
            <Spacer />
            <FormattedText
              value={portfolio.performance.profit}
              type='priceChange'
            />
          </HStack>
        </Card>
      ))}
    </SimpleGrid>
  );
};

export default PortfolioInfoList;