import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import Home from './components/Home';
import PortfolioDetail from './components/PortfolioDetail/PortfolioDetail';
import ProtectedRoute from './components/ProtectedRoutes';
import InvestmentDetail from './components/InvestmentDetail/InvestmentDetail';
import TransactionDetail from './components/TransactionDetail/TransactionDetail';

const App: React.FC = () => {

  return (
    < Box p={4} >
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route
          path="/portfolio/:id"
          element={
            <ProtectedRoute>
              <PortfolioDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/investment/:id"
          element={
            <ProtectedRoute>
              <InvestmentDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/transaction/:id"
          element={
            <ProtectedRoute>
              <TransactionDetail />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Box >
  );
};

export default App;