// src/components/FormattedText.tsx

import React from 'react';
import { Text, TextProps, HStack } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { CurrencyFormatter } from './CurrencyFormatter';

type FormattedTextType = 'percentChange' | 'priceChange' | 'price';

interface TypeConfigBase {
    shouldColor: boolean;
    showIcon: boolean;
}

interface ColoredTypeConfig extends TypeConfigBase {
    shouldColor: true;
    colors: {
        positive: string;
        negative: string;
        neutral: string;
    };
    icons?: {
        positive: React.ElementType;
        negative: React.ElementType;
    };
}

interface NonColoredTypeConfig extends TypeConfigBase {
    shouldColor: false;
}

type TypeConfig = ColoredTypeConfig | NonColoredTypeConfig;

const typeConfig: Record<FormattedTextType, TypeConfig> = {
    percentChange: {
        shouldColor: true,
        colors: {
            positive: 'green.400',
            negative: 'red.400',
            neutral: 'gray.400',
        },
        showIcon: true,
        icons: {
            positive: ArrowUpIcon,
            negative: ArrowDownIcon,
        },
    },
    priceChange: {
        shouldColor: true,
        colors: {
            positive: 'green.400',
            negative: 'red.400',
            neutral: 'gray.400',
        },
        showIcon: false, // No icons for priceChange
    },
    price: {
        shouldColor: false,
        showIcon: false,
    },
};

interface FormattedTextProps extends TextProps {
    value: number;
    type: FormattedTextType;
    currencyCode?: string; // Required if type is 'currency'
    defaultCurrencyCode?: string; // Optional default
}

const FormattedText: React.FC<FormattedTextProps> = ({
    value,
    type,
    currencyCode,
    defaultCurrencyCode = 'EUR',
    ...rest
}) => {
    const config = typeConfig[type];

    const isPositive = value > 0;
    const isNegative = value < 0;
    const isZero = value === 0;

    // Determine color based on config and value
    const color =
        config.shouldColor
            ? isPositive
                ? config.colors.positive
                : isNegative
                    ? config.colors.negative
                    : config.colors.neutral
            : undefined;

    // Determine the prefix (icon) based on config and value
    let prefix: React.ReactNode = null;
    if (config.shouldColor && config.showIcon) {
        if (isPositive && config.icons?.positive) {
            const PositiveIcon = config.icons.positive;
            prefix = (
                <PositiveIcon color={config.colors.positive} aria-label="Increase" />
            );
        } else if (isNegative && config.icons?.negative) {
            const NegativeIcon = config.icons.negative;
            prefix = (
                <NegativeIcon color={config.colors.negative} aria-label="Decrease" />
            );
        }
        // No icon for zero
    }

    // Format the value based on type
    let formattedValue: string = '';
    switch (type) {
        case 'percentChange':
            formattedValue = `${Math.abs(value).toFixed(2)}%`;
            break;
        case 'priceChange':
        case 'price':
            const codeToUse = currencyCode || defaultCurrencyCode;
            formattedValue = CurrencyFormatter.formatCurrency(Math.abs(value), codeToUse);
            break;
        default:
            formattedValue = `${value}`;
    }

    // Determine if a negative sign should be prefixed
    const displayValue =
        config.shouldColor && !isZero
            ? `${formattedValue}`
            : formattedValue;

    return (
        <HStack spacing={1} align="center" justifyContent={rest.justifyContent} {...rest}>
            {prefix}
            <Text color={color} fontWeight="medium">
                {displayValue}
            </Text>
        </HStack>
    );
};

export default FormattedText;