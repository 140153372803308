import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Flex,
    Heading,
    VStack,
    IconButton,
    Tag,
    TagLabel,
    HStack,
    Divider,
    Text,
    Center,
    Spinner,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { TransactionDetailModel } from '../../interfaces/TransactionDetailModel';
import FormattedText from '../FormattedText';
import TransactionDetailRow from './TransactionDetailRow';
import { MdAccountBalance, MdOutlineBusiness, MdSavings } from 'react-icons/md';
import { atom, useAtom } from 'jotai';
import { useAuth } from '../../contexts/AuthContext';

const modelAtom = atom<TransactionDetailModel | null>(null);

const TransactionDetail: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { service } = useAuth();
    const [model, setModel] = useAtom(modelAtom);

    useEffect(() => {
        const fetchInvestmentDetail = async () => {
            try {
                if (service && id) {
                    const data = await service.fetchTransactionDetail(id);
                    setModel(data);
                } else {
                    console.error('Invalid portfolio ID.');
                }
            } catch (err) {
                console.error('Failed to fetch portfolio:', err);
            }
        };

        fetchInvestmentDetail();
    }, [service, id, setModel]);

    return (
        <>
            {model ? (
                <>
                    <Flex align="baseline" mb={6}>
                        <IconButton
                            icon={<ArrowBackIcon />}
                            onClick={() => navigate(-1)}
                            colorScheme="blue"
                            aria-label="Go back"
                            isRound
                            mr={4}
                        />
                        <Heading as="h2" size="lg">
                            Transaction Details
                        </Heading>
                    </Flex>

                    <VStack align="stretch" spacing={3}>
                        <TransactionDetailRow label="Ticker" value={model.ticker} />
                        <TransactionDetailRow label="Date" value={new Date(model.purchaseDate).toLocaleDateString('en-us', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        })} />
                        <TransactionDetailRow label="Number of Shares" value={model.numberOfShares} />
                        <TransactionDetailRow
                            label="Price per Share"
                            value={
                                <FormattedText
                                    value={model.pricePerShareAtPurchase}
                                    type="price"
                                />
                            }
                        />
                        <TransactionDetailRow
                            label="Fees"
                            value={
                                <FormattedText
                                    value={model.fees}
                                    type="price"
                                />
                            }
                        />
                        <TransactionDetailRow
                            label="Total Cost"
                            value={
                                <FormattedText
                                    value={model.numberOfShares * model.pricePerShareAtPurchase}
                                    type="price"
                                />
                            }
                        />
                    </VStack>

                    <Divider m={4} />

                    <Flex wrap="wrap" gap={2}>
                        {model.portfolio && (
                            <Tag size="md" borderRadius="full">
                                <TagLabel>
                                    <HStack spacing={1}>
                                        <MdSavings />
                                        <Text>{model.portfolio}</Text>
                                    </HStack>
                                </TagLabel>
                            </Tag>
                        )}
                        {model.platform && (
                            <Tag size="md" borderRadius="full">
                                <TagLabel>
                                    <HStack spacing={1}>
                                        <MdOutlineBusiness />
                                        <Text>{model.platform}</Text>
                                    </HStack>
                                </TagLabel>
                            </Tag>
                        )}
                        {model.account && (
                            <Tag size="md" borderRadius="full">
                                <TagLabel>
                                    <HStack spacing={1}>
                                        <MdAccountBalance />
                                        <Text>{model.account}</Text>
                                    </HStack>
                                </TagLabel>
                            </Tag>
                        )}
                    </Flex>
                </>
            ) : (
                <Center h="60vh">
                    <Spinner size="xl" />
                </Center>
            )}
        </>
    );
};

export default TransactionDetail;