import axios, { AxiosRequestConfig } from 'axios';
import {
  PortfolioInfo,
  CreatePortfolioDTO,
  Portfolio,
  CreateTransactionDTO,
  Transaction,
  Ticker,
  PortfolioPerformanceTimeSeriesDTO,
} from '../interfaces';
import { InvestmentDetailModel } from '../interfaces/InvestmentDetailModel';
import { TransactionDetailModel } from '../interfaces/TransactionDetailModel';

const API_URL = '/api';

// Create an Axios instance for the service
const serviceAxios = axios.create();

// Interface for the service
export interface IGrodtService {
  fetchPortfolios(): Promise<PortfolioInfo[]>;
  fetchPortfolio(id: string): Promise<Portfolio>;
  createTransaction(transaction: CreateTransactionDTO): Promise<Transaction>;
  fetchPortfolioHistoricalPerformance(id: string): Promise<PortfolioPerformanceTimeSeriesDTO>;
  deleteTransaction(transactionID: string): Promise<void>;
  createPortfolio(portfolio: CreatePortfolioDTO): Promise<Portfolio>;
  fetchTickers(): Promise<Ticker[]>;
  fetchInvestmentDetail(ticker: string): Promise<InvestmentDetailModel>;
  fetchTransactionDetail(id: string): Promise<TransactionDetailModel>;
  searchTickers(keyword: string): Promise<Ticker[]>;
  createTicker(ticker: Ticker): Promise<Ticker>;
  setAuthToken(token: string): void;
  login(username: string, password: string): Promise<void>;
  getAuthToken(): string | null;
}

// Factory function to create the service with a login handler
export const createGrodtService = (handleLogin: () => Promise<void>): IGrodtService => {
  // Set token if available at service startup
  const storedToken = localStorage.getItem('authToken');
  if (storedToken) {
    serviceAxios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
  }

  // Response interceptor to handle 401
  serviceAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        try {
          await handleLogin();
          error.config.headers['Authorization'] = serviceAxios.defaults.headers.common['Authorization'];
          return serviceAxios(error.config);
        } catch (loginError) {
          return Promise.reject(loginError);
        }
      }
      return Promise.reject(error);
    }
  );

  class GrodtService implements IGrodtService {
    setAuthToken(token: string) {
      serviceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    getAuthToken(): string | null {
      return localStorage.getItem('authToken');
    }

    async login(username: string, password: string): Promise<void> {
      const authHeader = 'Basic ' + window.btoa(`${username}:${password}`);
      try {
        const response = await serviceAxios.post(`/login`, {}, { headers: { Authorization: authHeader } });
        const token = response.data.value;
        this.setAuthToken(token);
        localStorage.setItem('authToken', token);
        console.log('Logged in and token set:', token);
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    }

    async fetchPortfolios() {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/portfolios`,
      };
      return this.performRequest<PortfolioInfo[]>(config);
    }

    async fetchPortfolio(id: string) {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/portfolios/${id}`,
      };
      return this.performRequest<Portfolio>(config);
    }

    async createTransaction(transaction: CreateTransactionDTO) {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${API_URL}/transactions`,
        data: transaction,
      };
      return this.performRequest<Transaction>(config);
    }

    async fetchPortfolioHistoricalPerformance(id: string) {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/portfolios/${id}/historicalPerformance`,
      };
      return this.performRequest<PortfolioPerformanceTimeSeriesDTO>(config);
    }

    async deleteTransaction(transactionID: string) {
      const config: AxiosRequestConfig = {
        method: 'delete',
        url: `${API_URL}/transactions/${transactionID}`,
      };
      return this.performRequest<void>(config);
    }

    async createPortfolio(portfolio: CreatePortfolioDTO) {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${API_URL}/portfolios`,
        data: portfolio,
      };
      return this.performRequest<Portfolio>(config);
    }

    async fetchTickers() {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/tickers`,
      };
      return this.performRequest<Ticker[]>(config);
    }

    async fetchInvestmentDetail(ticker: string) {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/investments/${encodeURIComponent(ticker)}`,
      };
      return this.performRequest<InvestmentDetailModel>(config);
    }

    async fetchTransactionDetail(id: string) {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/transactions/${encodeURIComponent(id)}`,
      };
      return this.performRequest<TransactionDetailModel>(config);
    }

    async searchTickers(keyword: string) {
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${API_URL}/tickers/search/${encodeURIComponent(keyword)}`,
      };
      return this.performRequest<Ticker[]>(config);
    }

    async createTicker(ticker: Ticker) {
      const config: AxiosRequestConfig = {
        method: 'post',
        url: `${API_URL}/tickers`,
        data: ticker,
      };
      return this.performRequest<Ticker>(config);
    }

    private async performRequest<T>(config: AxiosRequestConfig): Promise<T> {
      try {
        console.log(`Sending ${config.method?.toUpperCase()} request to: ${config.url}`);
        const response = await serviceAxios(config);
        console.log(`Received response from ${config.url}: `, response.data);
        return response.data;
      } catch (error) {
        this.handleError(error);
        throw error;
      }
    }

    private handleError(error: unknown): void {
      console.error('Error making request:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', error.response?.data || error.message);
      }
    }
  }

  return new GrodtService();
};