import React from 'react';
import {
    Box,
    VStack,
    Text,
    HStack,
    Card
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Investment } from '../../interfaces/Investment';
import FormattedText from '../FormattedText';

interface InvestmentsListProps {
    investments: Investment[];
}

const InvestmentsList: React.FC<InvestmentsListProps> = ({ investments }) => {
    const navigate = useNavigate();

    const handleInvestmentClick = (id: string) => {
        navigate(`/investment/${id}`);
    };

    return (
        <VStack align="stretch" spacing={4} p={1}>
            {investments.map((investment, index) => (
                <Card
                    key={investment.name + index}
                    p={4}
                    onClick={() => handleInvestmentClick(investment.shortName)}
                    cursor="pointer"
                    _hover={{ boxShadow: 'lg' }}
                >
                    <HStack spacing="40px" alignItems="flex-start">
                        <Box flex="1" minW="0" overflow="hidden">
                            <Text isTruncated fontWeight="medium">{investment.name}</Text>
                        </Box>
                        <Box flexShrink={0}>
                            <FormattedText
                                value={investment.value}
                                type='price'
                                fontWeight="medium" />
                        </Box>
                    </HStack>
                    <HStack spacing="40px" alignItems="flex-start">
                        <Box flex="1" minW="0" overflow="hidden">
                            <FormattedText
                                value={investment.totalReturn}
                                type="percentChange"
                                fontSize="sm"
                            />
                        </Box>
                        <Box flexShrink={0}>
                            <FormattedText
                                value={investment.profit}
                                type="priceChange"
                                currencyCode={investment.currency.code}
                                fontSize="sm"
                            />
                        </Box>
                    </HStack>
                </Card>
            ))}
        </VStack>
    );
};

export default InvestmentsList;