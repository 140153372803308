import React from 'react';
import { Flex, Card, CardBody, Stat, StatLabel, StatNumber, StatHelpText } from '@chakra-ui/react';
import { Portfolio } from '../../interfaces/Portfolio';
import FormattedText from '../FormattedText';

interface PerformanceCardsProps {
    portfolio: Portfolio;
}

const PerformanceCards: React.FC<PerformanceCardsProps> = ({ portfolio }) => {
    return (
        <Flex
            direction={['column', 'row']}
            gap={4}
            align="stretch"
            wrap="wrap"
        >
            <Card flex="1" minW="200px" height="130px">
                <CardBody>
                    <Stat>
                        <StatLabel>Current Value</StatLabel>
                        <StatNumber>
                            <FormattedText
                                value={portfolio.performance.moneyOut}
                                type='price'
                            />
                        </StatNumber>
                    </Stat>
                </CardBody>
            </Card>
            <Card flex="1" minW="200px" height="130px">
                <CardBody>
                    <Stat>
                        <StatLabel>All Time</StatLabel>
                        <StatNumber>
                            <FormattedText
                                value={portfolio.performance.totalReturn * 1}
                                type="percentChange"
                            />
                        </StatNumber>
                        <StatHelpText>
                            <FormattedText
                                value={portfolio.performance.profit}
                                type='priceChange'
                            />
                        </StatHelpText>
                    </Stat>
                </CardBody>
            </Card>
        </Flex>
    );
};

export default PerformanceCards;