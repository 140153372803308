import React, { useState } from 'react';
import {
    Box,
    Heading,
    Button,
    FormControl,
    FormLabel,
    Input,
    Alert,
    AlertIcon,
    useToast,
    InputGroup,
    InputRightElement,
    IconButton,
} from '@chakra-ui/react';
import { FiLogIn } from 'react-icons/fi';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

interface LoginPageProps {
    onLogin: (username: string, password: string) => Promise<void>;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        try {
            await onLogin(username, password);
        } catch (err) {
            const errorMessage =
                err instanceof Error && err.message === 'Network Error'
                    ? 'Network error. Please check your internet connection.'
                    : 'Invalid username or password';
            setError(errorMessage);
            toast({
                title: 'Login failed.',
                description: errorMessage,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgGradient="linear(to-r, blue.500, teal.500)"
        >
            <Box
                width={{ base: '90%', sm: '400px' }}
                p={6}
                boxShadow="lg"
                borderRadius="md"
                bg="white"
            >
                <Heading mb={6} textAlign="center">
                    Podt
                </Heading>
                {error && (
                    <Alert status="error" mb={4}>
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <FormControl id="username" mb={4} isRequired>
                        <FormLabel>Username</FormLabel>
                        <Input
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={isSubmitting}
                        />
                    </FormControl>
                    <FormControl id="password" mb={4} isRequired>
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={isSubmitting}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={() => setShowPassword(!showPassword)}
                                    variant="ghost"
                                    size="sm"
                                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Button
                        colorScheme="blue"
                        type="submit"
                        isLoading={isSubmitting}
                        width="full"
                        leftIcon={<FiLogIn />}
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default LoginPage;