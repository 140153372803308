import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Collapse,
  FormControl,
  Select,
  Flex,
  FormLabel,
  Input,
  HStack,
  IconButton,
  Text,
  Card,
  Spacer
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';


import { CreateTransactionDTO } from '../../interfaces/CreateTransactionDTO';
import { Transaction } from '../../interfaces/Transaction';
import { Ticker } from '../../interfaces/Ticker';
import { useAuth } from '../../contexts/AuthContext';
import { FormInput, FormInputProps } from '../FormInput';

interface AddTransactionModalProps {
  portfolioID: string;
  isOpen: boolean;
  onClose: () => void;
  onAddTransaction: (transaciton: Transaction) => void;
}

const AddTransactionModal: React.FC<AddTransactionModalProps> = ({ portfolioID, isOpen, onClose, onAddTransaction }) => {
  const [ticker, setTicker] = useState('');
  const [currency, setCurrency] = useState('');
  const [price, setPrice] = useState('');
  const [shares, setShares] = useState('');
  const [fees, setFees] = useState('');
  const [platform, setPlatform] = useState('');
  const [account, setAccount] = useState('');
  const [transactionDate, setTransactionDate] = useState(new Date().toISOString().slice(0, 10));
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isSelectEnabled, setIsSelectEnabled] = useState(false);
  const [tickers, setTickers] = useState<Ticker[]>([]);
  const [searchedTickers, setSearchedTickers] = useState<Ticker[]>([]);
  const [selectedSearchedTicker, setSelectedSearchedTicker] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');


  const [currentView, setCurrentView] = useState('view1');
  const { service } = useAuth();

  const resetForm = () => {
    setTicker('');
    setCurrency('');
    setPrice('');
    setShares('');
    setFees('');
    setPlatform('');
    setAccount('');
    setTransactionDate(new Date().toISOString().slice(0, 10)); // Reset to today's date
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }

    const getTickers = async () => {
      if (service) {
        const data = await service.fetchTickers();
        setTickers(data);
      }
    };

    getTickers();
  }, [service, isOpen]);

  useEffect(() => {
    const checkRequiredFields = () => {
      return ticker.trim() !== '' && price.trim() !== '' && shares.trim() !== '' && transactionDate.trim() !== '';
    };

    setIsSaveEnabled(checkRequiredFields());
  }, [ticker, price, shares, transactionDate]);

  const formFields: FormInputProps[] = [
    { id: 'currency', label: 'Currency', defaultValue: currency, isDisabled: true, isRequired: true, type: "text", onChange: setCurrency },
    { id: 'price', label: 'Price', placeholder: '1.23', isRequired: true, type: "number", onChange: setPrice },
    { id: 'shares', label: 'Shares', placeholder: '1.23', isRequired: true, type: "number", onChange: setShares },
    { id: 'date', label: 'Trade Date', defaultValue: transactionDate, isRequired: true, type: "date", onChange: setTransactionDate },
    { id: 'fees', label: 'Fees', isRequired: false, type: "number", onChange: setFees },
    { id: 'platform', label: 'Platform', isRequired: false, type: "text", onChange: setPlatform },
    { id: 'account', label: 'Account', isRequired: false, type: "text", onChange: setAccount },
  ];

  const searchTickers = async () => {
    try {
      if (service) {
        const response = await service.searchTickers(searchKeyword)
        setSearchedTickers(response)
      } else {
        console.error('service nil');
      }
    } catch (err) {
      console.error('Failed to search tickers:', err);
    }
  };

  const createTicker = async (ticker: Ticker) => {
    try {
      if (service) {
        await service.createTicker(ticker)
        setTickers(tickers.concat(ticker))
        setTicker(ticker.symbol)
        setCurrentView('view1')
      } else {
        console.error('service nil');
      }
    } catch (error) {
      console.error('Failed to search tickers:', error)
    }
  };


  const handleSearchedTickerSelection = (ticker: Ticker) => {
    if (selectedSearchedTicker === ticker.symbol) {
      setSelectedSearchedTicker('')
      setIsSelectEnabled(false)
      return
    }
    setSelectedSearchedTicker(ticker.symbol)
    setIsSelectEnabled(true)
  }

  const handleSelect = () => {
    let selectedTicker = searchedTickers.find(ticker => ticker.symbol === selectedSearchedTicker)
    if (selectedTicker) {
      createTicker(selectedTicker)
    }
  }

  const handleSave = async () => {
    const newTransaction: CreateTransactionDTO = {
      portfolio: portfolioID,
      platform: platform || "",
      account: account || "",
      purchaseDate: (new Date(transactionDate)).toISOString(),
      ticker: ticker,
      currency: "EUR",
      fees: parseFloat(fees) || 0,
      numberOfShares: parseFloat(shares),
      pricePerShare: parseFloat(price)
    };

    if (service) {
      try {
        const response = await service.createTransaction(newTransaction)
        onAddTransaction(response)
      } catch (error) {
        console.error('Failed to fetch portfolio details:', error)
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => { onClose(); resetForm(); }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {currentView === 'view1' && (
            "Add new transaction"
          )}
          {currentView === 'view2' && (
            "Search ticker"
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {currentView === 'view1' && (
            <VStack spacing={2} align="stretch">
              <FormControl isRequired>
                <Flex alignItems="center">
                  <FormLabel htmlFor={ticker} mb={0} flex={1} textAlign="right">Ticker</FormLabel>
                  <Select flex={2.26} placeholder='Select ticker' onChange={(e) => {
                    if (e.target.value === `search`) {
                      setCurrentView('view2')
                      return
                    }
                    setTicker(e.target.value)
                    let selectedTicker = tickers.find(ticker => ticker.symbol === e.target.value)
                    if (selectedTicker) {
                      setCurrency(selectedTicker.currency)
                    } else {
                      setCurrency('')
                    }
                  }}>
                    {tickers.map(ticker => (
                      <option value={ticker.symbol}>({ticker.symbol}) {ticker.name}</option>
                    ))}
                    <option value='search'>Seach for new...</option>
                  </Select>
                </Flex>
              </FormControl>
              <Collapse in={ticker.trim() !== ''} animateOpacity>
                <VStack spacing={2} align="stretch">
                  {formFields.map(field => (
                    <FormInput
                      id={field.id}
                      label={field.label}
                      placeholder={field.placeholder}
                      defaultValue={field.defaultValue}
                      type={field.type}
                      isDisabled={field.isDisabled}
                      isRequired={field.isRequired}
                      onChange={field.onChange}
                    />
                  ))}
                </VStack>
              </Collapse>
            </VStack>
          )}
          {currentView === 'view2' && (
            <VStack spacing={2} align="stretch">
              <HStack>
                <Input id='searchKeyword' placeholder='Ticker' type="text" onChange={(e) => setSearchKeyword(e.target.value)} />
                <IconButton
                  colorScheme="blue"
                  aria-label='Search database'
                  onClick={searchTickers}
                  icon={<SearchIcon />} />
              </HStack>
              <VStack spacing={4} align="strech">
                {searchedTickers.map(ticker => (
                  <Card key={ticker.symbol}
                    p={3}
                    outline={selectedSearchedTicker === ticker.symbol ? "2px solid" : "none"}
                    outlineColor={selectedSearchedTicker === ticker.symbol ? "blue" : "transparent"}
                    onClick={() => handleSearchedTickerSelection(ticker)}>
                    <Flex align="center" gap="3">
                      <VStack flex="1" align="strech">
                        <HStack>
                          <Text as='b'>{ticker.symbol}</Text>
                          <Spacer />
                          <Text>{ticker.region}</Text>
                        </HStack>
                        <Text>{ticker.name}</Text>
                        <Text fontSize="sm">{ticker.currency}</Text>
                      </VStack>
                    </Flex>
                  </Card>
                ))}
              </VStack>
            </VStack>
          )}
        </ModalBody>
        {currentView === 'view1' && (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave} isDisabled={!isSaveEnabled}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        )}
        {currentView === 'view2' && (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSelect} isDisabled={!isSelectEnabled}>
              Select
            </Button>
            <Button variant="ghost" onClick={() => setCurrentView('view1')}>Cancel</Button>
          </ModalFooter>
        )}
      </ModalContent >
    </Modal >
  );
};

export default AddTransactionModal;
