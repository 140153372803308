import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Flex,
} from '@chakra-ui/react';

import { useAuth } from '../contexts/AuthContext';
import { CreatePortfolioDTO } from '../interfaces/CreatePortfolioDTO';
import { Portfolio } from '../interfaces/Portfolio';

interface AddPortfolioModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddPortfolio: (transaciton: Portfolio) => void;
}

const AddPortfolioModal: React.FC<AddPortfolioModalProps> = ({ isOpen, onClose, onAddPortfolio }) => {
    const [name, setName] = useState('');
    const [currency, setCurrency] = useState('');
    const { service } = useAuth();

    const handleSave = () => {
        const newPortfolio: CreatePortfolioDTO = {
            name: name,
            currency: currency,
        };

        service!.createPortfolio(newPortfolio)
            .then(response => onAddPortfolio(response))
            .catch(error => console.error('Failed to fetch portfolio details:', error));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add a new portfolio</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={2} align="stretch">
                        <FormControl isRequired>
                            <Flex alignItems="center">
                                <FormLabel htmlFor="name" mb={0} flex="1" textAlign="right">Name</FormLabel>
                                <Input id="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} flex="2" />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired>
                            <Flex alignItems="center">
                                <FormLabel htmlFor="currency" mb={0} flex="1" textAlign="right">Currency</FormLabel>
                                <Input id="currency" placeholder="EUR" value={currency} onChange={(e) => setCurrency(e.target.value)} flex="2" />
                            </Flex>
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSave}>
                        Add
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    );
};

export default AddPortfolioModal;
